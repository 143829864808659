<template>
	<div class="contanier">
		<!-- table -->
		<div class="table-container">
			<div class="area-content" v-for=" item in menuList" :key='item.name+item.id'>
				<div class="left">
					<el-checkbox :disabled='item.disabled' v-model="item.checked" :indeterminate="item.isIndeterminate"
						@change="changeClassify(item.id)"></el-checkbox>
					{{item.name}}
				</div>
				<div class="right">
					<div class="item" v-for="tt in item.child" :key="tt.name+tt.id">
						<el-checkbox @change="changeProvince(tt.id)" style="margin-right:10px" :disabled='tt.disabled'
							:indeterminate="tt.isIndeterminate" v-model="tt.checked"></el-checkbox>

						<el-dropdown :hide-on-click="false" v-if="tt.child.length">
							<span class="el-dropdown-link">
								{{tt.name}}
								<template v-if="tt.checkedLen>0">({{tt.checkedLen}})</template>
								<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown" class="select-ship-dropdown">
								<el-dropdown-item v-for="ttt in tt.child" :key="ttt.name+ttt.id">
									<el-checkbox @change="changeCity" style="margin-right:10px" :disabled='ttt.disabled'
										v-model="ttt.checked">{{ttt.name}}</el-checkbox>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>

						<span v-else>{{tt.name}}</span>
					</div>
				</div>
			</div>
			<el-checkbox :value="selectAll" @change="changeSelectAll">全选</el-checkbox>
		</div>
		<div class="table-container">
		</div>
		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="submitEdit">确 定</el-button>
		</div>
	</div>
</template>
<script>
	import areaData from '@/components/AreaData_classify.json'
	export default {
		name: 'selectPro',
		props: {
			// 选项
			defaultData: {
				type: Array
			},
			// 排除项
			excludeData: {
				type: Array,
				default: () => {
					return []
				}
			},
		},
		data() {
			return {
				menuList: [],
				checkedList: [],
				multipleSelection: [],

				selectAll: false,
			}
		},
		computed: {},
		watch: {},
		beforeMount() {
			// console.log("选项", this.defaultData)
			// console.log("排除项", this.excludeData)
			this.initAreaData()
			this.$forceUpdate()
		},
		methods: {
			recursiveArea(areaData, index) {
				index++
				let areaMap = areaData.map(t => {
					let childArea = []
					if (index < 4) {
						if (index == 3 && t.child.length == 1) {
							// 直辖市
							childArea = []
						} else {
							// 非直辖市
							childArea = this.recursiveArea(t.child, index)
						}
					} else {
						childArea = []
					}
					return {
						id: t.id,
						name: t.name,
						child: childArea
					}
				})
				return areaMap
			},

			// check回显、disabled回显
			initAreaData() {
				let defaults = this.defaultData
				let excludeData = this.excludeData.map(Number)
				this.menuList = this.recursiveArea(areaData, 1)
				// console.log(defaults)
				// check回显、disabled回显
				this.menuList.forEach(t => {
					t.child.forEach(tt => {
						if (tt.child.length) {
							tt.child.forEach(ttt => {
								//是否选中
								if (defaults.indexOf(Number(tt.id)) >= 0 || defaults.indexOf(tt.name) >= 0) {
									ttt.checked = true
								} else {
									ttt.checked = defaults.indexOf(Number(ttt.id)) >= 0 || defaults.indexOf(ttt.name) >= 0
								}
								//是否禁用
								if (excludeData.indexOf(Number(tt.id)) >= 0) {
									ttt.disabled = true
								} else {
									ttt.disabled = excludeData.indexOf(Number(ttt.id)) >= 0
								}
							})
						} else {
							tt.checked = defaults.indexOf(Number(tt.id)) >= 0 || defaults.indexOf(tt.name) >= 0
							tt.disabled = excludeData.indexOf(Number(tt.id)) >= 0
						}
					})
				})
				this.initChecked()
			},
			//更换一次状态，遍历一次：根据最底层市区的状态来判断省和分类的check状态、disable状态
			initChecked() {
				let classifyChecks = 0
				let classifyDisabled = 0
				this.menuList.forEach(t => {
					//t:区域
					t.checkedLen = 0
					t.disabledLen = 0
					t.child.forEach(tt => {
						//tt:省份
						tt.checkedLen = 0
						tt.disabledLen = 0
						if (tt.child.length) {
							tt.child.forEach(ttt => {
								//ttt:市区
								if (ttt.checked) {
									tt.checkedLen++
								}
								if (ttt.disabled) {
									tt.disabledLen++
								}
							})

							tt.disabled = tt.disabledLen == tt.child.length && tt.disabledLen > 0
							tt.checked = (tt.checkedLen + tt.disabledLen) == tt.child.length && tt
								.checkedLen > 0
							tt.isIndeterminate = tt.checkedLen > 0 && (tt.checkedLen + tt.disabledLen) !=
								tt.child.length
						}

						if (tt.checked) {
							t.checkedLen++
						}
						if (tt.disabled) {
							t.disabledLen++
						}
					})
					t.disabled = t.disabledLen == t.child.length && t.disabledLen > 0
					t.checked = (t.checkedLen + t.disabledLen) == t.child.length && t.checkedLen > 0
					t.isIndeterminate = t.checkedLen > 0 && (t.checkedLen + t.disabledLen) != t.child.length

					if (t.checked) {
						classifyChecks++
					}
					if (t.disabled) {
						classifyDisabled++
					}
				})
				this.selectAll = (this.menuList.length == (classifyChecks + classifyDisabled)) && classifyChecks > 0
				this.$forceUpdate()
			},
			// change all
			changeSelectAll(checkValue) {
				this.recursiveCheck(this.menuList, checkValue)
				this.initChecked()
			},
			//change classify
			changeClassify(id) {
				this.menuList.forEach(t => {
					if (t.id == id) {
						t.isIndeterminate = false
						t.child = this.recursiveCheck(t.child, t.checked)
					}
				})
				this.initChecked()
			},
			// change province
			changeProvince(id) {
				this.menuList.forEach(t => {
					t.child.forEach((tt) => {
						if (tt.id == id) {
							tt.isIndeterminate = false
							tt.child.forEach((ttt) => {
								ttt.checked = tt.checked
							})
						}
					})
				})
				this.initChecked()
			},
			// change city
			changeCity(id) {
				this.initChecked()
			},
			recursiveCheck(list, checkValue) {
				list.forEach(t => {
					t.isIndeterminate = false
					t.checked = t.disabled ? false : checkValue
					if (t.child.length) {
						this.recursiveCheck(t.child, t.checked)
					}
				})
				return list
			},
			//  确定选择
			submitEdit() {
				let selectionId = []
				let selectionName = []
				this.menuList.forEach(t => {
					t.child.forEach(tt => {
						if (tt.child.length) {
							// 非直辖市
							if (tt.checkedLen != tt.child.length) {
								tt.child.forEach(ttt => {
									if (ttt.checked) {
										selectionId.push(ttt.id)
										selectionName.push(ttt.name)
									}
								})
							} else {
								selectionId.push(tt.id)
								selectionName.push(tt.name)
							}
						} else {
							// 直辖市
							if (tt.checked) {
								selectionId.push(tt.id)
								selectionName.push(tt.name)
							}
						}

					})
				})
				this.$emit('getCheckedArea', selectionId, selectionName)
			}
		}
	}
</script>
<style lang="less" scoped>
	.contanier {
		position: relative;
		overflow: hidden;
	}

	.dialog-footer {
		text-align: right;

		.button {
			margin: 30px auto 0 auto;
			display: block;
			width: 200px;
		}
	}

	.area-content {
		display: flex;
		flex-direction: row;
		margin-bottom: 10px;

		.left {
			width: 100px;
			line-height: 40px;
		}

		.right {
			width: calc(100% - 100px);
			line-height: 40px;

			.el-dropdown-link {
				cursor: pointer;
			}

			.item {
				display: inline-block;
				width: 25%;
			}
		}
	}

	.select-ship-dropdown {
		max-height: 500px;
		overflow-y: scroll;
	}
</style>
