<template>
	<div class="container">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
			<el-card class="box-card">
				<div slot="header" class="clearfix"><span>商城设置</span></div>
				<el-form-item label="商城名称：" :inline-message="true" prop="MallName">
					<el-input v-model="ruleForm.MallName" placeholder="最多输入12个字" maxlength="12"></el-input>
				</el-form-item>
				<el-form-item label="短信签名：" :inline-message="true" prop="SmsSgin">
					<el-input v-model="ruleForm.SmsSgin" placeholder="最多输入12个字" maxlength="12"></el-input>
				</el-form-item>
				<el-form-item label="联系电话：">
					<el-input v-model="ruleForm.AreaCode"
						onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[0-9]\d*/)||'';}).call(this);"
						placeholder="区号" style="width: 100px;" maxlength="5"></el-input>
					<span style="margin: 0 10px;">--</span>
					<el-form-item style="display: inline-block;" :inline-message="true" prop="ContactPhone">
						<el-input v-model="ruleForm.ContactPhone"
							onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[1-9]\d*/)||'';}).call(this);"
							placeholder="请输入联系电话，便于客户售后联系" maxlength="11"></el-input>
					</el-form-item>
				</el-form-item>
				<el-form-item label="商城LOGO：" prop="MallLogoUrl">
					<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false"
						:on-success="handleAvatarSuccess" list-type="picture-card"
						style="display: inline-block;marin-left:10px;">
						<img v-if="ruleForm.MallLogoUrl" :src="imgUrl + ruleForm.MallLogoUrl" class="upload-avatar" />
						<i v-else class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<!-- prop="mallFooterLogoUrl" -->
				<el-form-item label="商城底部LOGO：" style="margin-top: 35px;">
					<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false"
						:on-success="handleFooterAvatarSuccess" list-type="picture-card"
						style="display: inline-block;marin-left:10px;">
						<img v-if="ruleForm.MallButtomLogoUrl" :src="imgUrl + ruleForm.MallButtomLogoUrl"
							class="upload-avatar" />
						<i v-else class="el-icon-plus"></i>
					</el-upload>
					<br>
					<el-button type="text" @click="deleteMallButtomLogoUrl">清空</el-button>
				</el-form-item>
				<el-form-item label="商城风格：" prop="MainColor">
					<el-color-picker v-model="ruleForm.MainColor"></el-color-picker>
				</el-form-item>
			</el-card>

			<!-- <el-card class="box-card" style="margin:10px 0 10px 0">
				<div slot="header" class="clearfix"><span>企业微信欢迎语</span></div>
				<div class="content">
					<el-form-item label="欢迎语：">
						<el-input v-model="ruleForm.Welcom" type="textarea" :rows="4" placeholder="在客户与员工好友添加成功后，自动回复的内容，最多500字"
						 maxlength="500"></el-input>
					</el-form-item>
				</div>
			</el-card> -->
			<el-card class="box-card payMoneys-with" style="margin:10px 0 10px 0">
				<div slot="header" class="clearfix"><span>交易设置</span></div>
				<div class="content">
					<el-form-item label="待付款订单自动取消时间：" label-width="300" :required="true">
						<div class="filter-container">
							<div class="filter-item">订单拍下后</div>
							<div class="filter-item">
								<el-form-item label="" label-width="0" prop="WaitPayOrderCancelTimeShow">
									<el-input v-model="ruleForm.WaitPayOrderCancelTimeShow" clearable
										onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) );"
										onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this);this.v();"
										onblur="this.v();this.v();" style="width:120px;margin:0 10px;">
									</el-input>
									<span>分钟内未付款，订单自动取消</span>
								</el-form-item>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="发货后,自动确认收货时间：" label-width="300" :required="true">
						<div class="filter-container">
							<div class="filter-item">订单发货后</div>
							<div class="filter-item">
								<el-form-item label="" label-width="0" prop="AutomaticReceivingTimeToSecondShow">
									<el-input v-model="ruleForm.AutomaticReceivingTimeToSecondShow" clearable
										onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) );"
										onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this);this.v();"
										onblur="this.v();this.v();" style="width:120px;margin:0 10px;">
									</el-input>
									<span>天，订单自动确认收货</span>
								</el-form-item>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="自动同意退货/退款申请：" label-width="300" :required="true">
						<el-radio v-model="ruleForm.IsOpenAutoConsentSupportRequest" :label="1">开启</el-radio>
						<el-radio v-model="ruleForm.IsOpenAutoConsentSupportRequest" :label="0">关闭</el-radio>
						<div class="filter-container" v-if="ruleForm.IsOpenAutoConsentSupportRequest==1"
							style="margin-left:178px">
							<div class="filter-item">买家提交申请后</div>
							<div class="filter-item">
								<el-form-item label="" label-width="0"
									:prop="ruleForm.IsOpenAutoConsentSupportRequest==1?'AutoConsentSupportRequestTimeShow':'nocheck'"
									:rules='ruleForm.IsOpenAutoConsentSupportRequest==1?rules.AutoConsentSupportRequestTimeShow:rules.nocheck'>
									<el-input v-model="ruleForm.AutoConsentSupportRequestTimeShow" clearable
										onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) );"
										onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this);this.v();"
										onblur="this.v();this.v();" style="width:120px;margin:0 10px;">
									</el-input>
									<span>天商家未处理，自动同意退货/退款申请</span>
								</el-form-item>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="买家退货寄回地址：" label-width="300" required></el-form-item>
					<div style="margin:-57px 0 0 145px">
						<el-form-item label="收货人姓名：" :inline-message="true" prop="ReceiveName">
							<el-input v-model="ruleForm.ReceiveName" placeholder="最多输入15个字" maxlength="15"></el-input>
						</el-form-item>
						<el-form-item label="收货人手机号：" :inline-message="true" prop="ReceivePhone">
							<el-input v-model="ruleForm.ReceivePhone" maxlength="11"></el-input>
						</el-form-item>
						<el-form-item label="收货地址：">
							<el-form-item :inline-message="true" prop="AddressArr">
								<el-cascader v-model="ruleForm.AddressArr" separator="-" :props="props"
									:options="dataList" clearable @change="handleAddressChange"></el-cascader>
							</el-form-item>
							<el-form-item :inline-message="true" prop="ReceiveAddress">
								<el-input style="margin-top:10px;" type="textarea" :rows="4"
									v-model="ruleForm.ReceiveAddress" maxlength="100"></el-input>
							</el-form-item>
						</el-form-item>
					</div>
					<el-form-item label="到店自提/同城配送订单退货，退回门店：" label-width="300px">
						<div>
							<el-radio-group v-model="ruleForm.IsRefundToShop" style="margin-top:13px">
								<el-radio :label="true">开启</el-radio>
								<el-radio :label="false">关闭</el-radio>
								<div class="grayfont" style="margin:10px 0px 0px 0px">
									开启后，到店自提订单或同城配送订单产生退货退款时，买家退货寄回地址将自动发送门店地址（到店自提订单将发送最终提货门店的地址；同城配送订单将发送下单门店地址）
								</div>
							</el-radio-group>
						</div>
					</el-form-item>
					<el-form-item label="买家寄回退货商品后,商家自动确认收货时间：" label-width="300" prop="IsOpenMallAutomaticReceiving">
						<el-radio v-model="ruleForm.IsOpenMallAutomaticReceiving" :label="1">开启</el-radio>
						<el-radio v-model="ruleForm.IsOpenMallAutomaticReceiving" :label="0">关闭</el-radio>
						<div v-if="ruleForm.IsOpenMallAutomaticReceiving ==1" class="filter-container"
							style="margin-left:305px">
							<div class="filter-item">买家寄回后</div>
							<div class="filter-item">
								<el-form-item label-width="0"
									:prop="ruleForm.IsOpenMallAutomaticReceiving ==1?'MallAutomaticReceivingTimeShow':'nocheck'"
									:rules='ruleForm.IsOpenMallAutomaticReceiving ==1?rules.MallAutomaticReceivingTimeShow:rules.nocheck'>
									<el-input v-model="ruleForm.MallAutomaticReceivingTimeShow" clearable
										onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) );"
										onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this);this.v();"
										onblur="this.v();this.v();" style="width:120px;margin:0 10px;">
									</el-input>
									<span>天，商家自动确认收货并且自动退款</span>
								</el-form-item>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="到店自提单自动退款：" label-width="300px" prop="IsOpenPickUpOrderAutoRefund" :inline-message="true" style="margin-bottom:10px">
						<el-radio-group v-model="ruleForm.IsOpenPickUpOrderAutoRefund" class="margin-right-20">
							<el-radio :label="true">开启</el-radio>
							<el-radio :label="false">关闭</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="" label-width="300px" v-if="ruleForm.IsOpenPickUpOrderAutoRefund">
						<div class="flex flex-align-start gap-10">
							<el-checkbox :label="0" v-model="ruleForm.IsOpenPickUpOrdinaryOrderAutoRefund"> <span></span> </el-checkbox>
							<div class="color-606266 font-14">
								<div class="flex flex-align-center">
									普通订单 <span class="color-999999 margin-left-10">仅订单类型为普通订单、赠品订单，适用该规则</span>
								</div>
								<div class="flex flex-align-center gap-10">
									实付金额≤
									<el-form-item label-width="0px" prop="PickUpOrdinaryOrderAutoRefundPaidMoney" :rules="ruleForm.IsOpenPickUpOrdinaryOrderAutoRefund?rules.twoDecimal:rules.nocheck">
										<el-input v-model="ruleForm.PickUpOrdinaryOrderAutoRefundPaidMoney" class="margin-right-10" style="width:100px" :disabled="!ruleForm.IsOpenPickUpOrdinaryOrderAutoRefund"
											oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))">
										</el-input>元的订单，购买后
									</el-form-item>
									<el-form-item label-width="0px" prop="PickUpOrdinaryOrderAutoRefundInStoreDay" :rules="ruleForm.IsOpenPickUpOrdinaryOrderAutoRefund?rules.days31:rules.nocheck">
										<el-input v-model="ruleForm.PickUpOrdinaryOrderAutoRefundInStoreDay" class="margin-right-10" style="width:100px" :disabled="!ruleForm.IsOpenPickUpOrdinaryOrderAutoRefund"
											onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
											onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
										</el-input>天内未到店提货，订单自动退款
									</el-form-item>
								</div>
							</div>
						</div>
						<div class="flex flex-align-start gap-10 margin-top-20">
							<el-checkbox :label="1" v-model="ruleForm.IsOpenPickUpActivityOrderAutoRefund"> <span></span> </el-checkbox>
							<div class="color-606266 font-14">
								<div class="flex flex-align-center">
									限时折扣活动单 <span class="color-999999 margin-left-10">订单中包含限时折扣活动商品，适用该规则</span>
								</div>
								<div class="flex flex-align-center gap-10">
									实付金额≤
									<el-form-item label-width="0px" prop="PickUpActivityOrderAutoRefundPaidMoney" :rules="ruleForm.IsOpenPickUpActivityOrderAutoRefund?rules.twoDecimal:rules.nocheck">
										<el-input v-model="ruleForm.PickUpActivityOrderAutoRefundPaidMoney" class="margin-right-10" style="width:100px" :disabled="!ruleForm.IsOpenPickUpActivityOrderAutoRefund"
											oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))">
										</el-input>元的订单，购买后
									</el-form-item>
									<el-form-item label-width="0px" prop="PickUpActivityOrderAutoRefundInStoreDay" :rules="ruleForm.IsOpenPickUpActivityOrderAutoRefund?rules.days31:rules.nocheck">
										<el-input v-model="ruleForm.PickUpActivityOrderAutoRefundInStoreDay" class="margin-right-10" style="width:100px" :disabled="!ruleForm.IsOpenPickUpActivityOrderAutoRefund"
											onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
											onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
										</el-input>天内未到店提货，订单自动退款
									</el-form-item>
								</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="到店自提单提货通知：" label-width="300px" required>
						<div class="flex">
							<el-radio-group v-model="ruleForm.IsOpenPickUpOrderTakeGoodsNotice" class="margin-right-20 margin-top-10">
								<el-radio :label="true">开启</el-radio>
								<el-radio :label="false">关闭</el-radio>
							</el-radio-group>
							<el-button type="text" @click="lookShow(4)">通知示例</el-button>
						</div>
						<div class="flex flex-align-center gap-10" v-if="ruleForm.IsOpenPickUpOrderTakeGoodsNotice">
							买家购买后
							<el-form-item label-width="0px" prop="PickUpOrderTakeGoodsNoticeNoEnterStoreDay" :rules="rules.days31">
								<el-input v-model="ruleForm.PickUpOrderTakeGoodsNoticeNoEnterStoreDay" class="margin-right-10" style="width:100px"
									onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
           				onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
								</el-input>天内未到店提货，发送通知提醒
							</el-form-item>
						</div>
					</el-form-item>

					<el-form-item label="已发货订单，最后一次退款时同时退回运费：" label-width="300" prop="IsOpenSendOrderSupportFreight">
						<el-radio v-model="ruleForm.IsOpenSendOrderSupportFreight" :label="true">开启</el-radio>
						<el-radio v-model="ruleForm.IsOpenSendOrderSupportFreight" :label="false">关闭</el-radio>
					</el-form-item>

					<el-form-item label="拼团订单可申请售后时间：" :required="true" label-width="300px">
						<div style="overflow: hidden;">
							<el-radio v-model="ruleForm.SpellGroupOrderSupportType" :label="0"
								style='margin-bottom:10px;'>
								<span>成团后</span>
								<el-form-item
									:prop="ruleForm.SpellGroupOrderSupportType ==  0?'SpellGroupOrderSupportDay':'nocheck'"
									style="display:inline-block;"
									:rules='ruleForm.SpellGroupOrderSupportType == 0?rules.SpellGroupOrderSupportDay:rules.nocheck'>
									<el-input :disabled='ruleForm.SpellGroupOrderSupportType==1'
										v-model="ruleForm.SpellGroupOrderSupportDay"
										@input='ruleForm.SpellGroupOrderSupportDay=validateInt(ruleForm.SpellGroupOrderSupportDay)'
										style="width:120px;margin:0 10px;">
									</el-input>
								</el-form-item>
								<span>天可申请退款</span>
							</el-radio>
							<br>
							<el-radio v-model="ruleForm.SpellGroupOrderSupportType" :label="1">订单发货后可申请退款</el-radio>
						</div>
					</el-form-item>

					<el-form-item label="积分兑换订单，退款时退还积分：" label-width="300px">
						<el-radio-group v-model="ruleForm.IsRefundCancelOrderPoint">
							<el-radio :label="true">开启</el-radio>
							<el-radio :label="false">关闭</el-radio>
						</el-radio-group>
						<div class = "grayFont">开启后，积分兑换订单全部退款（订单关闭）时，退还积分给买家 </div>
					</el-form-item>

					<el-form-item label="礼包授权订单备注：" label-width="300px" key="note" v-if="mallInfo.MallId==423||mallInfo.MallId==134">
						<div v-for="(v,i) in ruleForm.OrderRemarkConfig" :key="'noteDiv'+i" class = "flexRow" style="margin:0px 10px 20px 0px">
							<el-form-item :prop="'OrderRemarkConfig.'+i+'.Name'" :rules="rules.Name">
								<el-input v-model.trim="v.Name" style="width:140px"></el-input>
							</el-form-item>
							<el-select v-model="v.ConfigType" style="margin:0px 10px" class = "noteSelect" :key="'noteSelect'+i" @change="(e)=>{noteSelectChange(e,i)}">
								<el-option :value="0" label="文本格式"></el-option>
								<el-option :value="1" label="下拉筛选"></el-option>
							</el-select>
							<!-- 这里如果使用v-if 当前一个选项是文本格式时 下标i对应的popover会错乱 导致doClose()无法关闭 -->
							<el-popover	placement="right"	trigger="click" v-show="v.ConfigType==1" ref="notePopover" @show="popShow(i)">
								<div>
									<div class = "titFont">管理选项</div>
									<div class = "grayFont">每行代表1个选项，可添加或删除选项；选项最多可添加30个</div>
									<el-input type="textarea" v-model="v.OptionListStr" @input="$forceUpdate()" style="width:400px" :key="'textarea'+i"
									 :autosize="{ minRows: 12, maxRows: 12 }" resize="none"></el-input>
									<div style="text-align:right;margin-top:10px">
										<el-button @click="cancelPopover(i)">取消</el-button>
										<el-button type="primary" @click="noteSave(i)">保存</el-button>
									</div>
								</div>
								<el-button slot="reference" type="text" style="margin-right:10px">管理选项</el-button>
							</el-popover>
							<el-button type="text" style="color:#f56c6c" @click="delNote(i)">删除</el-button>
						</div>
						<el-button type="text" v-if="ruleForm.OrderRemarkConfig.length<5" @click="pushNote">+添加备注字段</el-button>
						<div class ="grayFont">买家购买礼包授权商品时可以选择性填写的附加备注内容。最多添加5个字段</div>
					</el-form-item>

					<el-form-item label="全店限售区域：" label-width="300px">
						<el-button type="text" @click="areaShow=true">管理限售区域</el-button>
						<div class="AreaBox" style="width:80%">
							<div class="top">限售区域</div>
							<div class="contentBox">
								<span v-if="!ruleForm.RestrictedAreas.length">暂未设置限售区域</span>
								<div v-else>
									<el-tag v-for="(v,i) in ruleForm.RestrictedAreas" :key="i" closable @close="closeTag(i)" style="margin:0px 10px 10px 0px">{{v}}</el-tag>
								</div>
							</div>
						</div>
					</el-form-item>

					<el-form-item label="第三方同城配送品类设置：" label-width="300px">
						<div class="flexRow" style="align-items:flex-start">
							<div style="white-space:nowrap;color:#606266">主营品类：</div>
							<div>
								<el-select v-model="ruleForm.MainCategory" clearable placeholder="请选择主营品类" style="margin:0px 10px">
									<el-option v-for="(v,i) in MainCategorylist" :key="i" :value="v.value" :label="v.label"></el-option>
								</el-select>
								<div class="grayfont" style="margin:5px 0px 20px 10px;font-size:12px">品类影响第三方配送服务商的配送费，请根据实际情况选择（骑手取货的实际商品与设置的品类严重不符时，可能会遭到骑手到店拒单或临时要求加价，造成无法及时履约的风险）</div>
							</div>
						</div>
						<!-- <div class="flexRow">
							<div style="white-space:nowrap">特殊品类：</div>
							<div style="color:#606266">当购买的商品包含</div>
							<el-select v-model="ruleForm.SpecialProdctCategorys" multiple clearable filterable placeholder="请选择商品分类" style="margin:0px 10px">
								<el-option v-for="item in SpecialProdctCategoryslist" :key="item.Id" :label="item.GroupName" :value="item.Id"></el-option>
							</el-select>
							<div style="color:#606266">时，按照【其他】品类提交给第三方配送服务商发单</div>
						</div> -->
					</el-form-item>

					<el-form-item label="买家自助修改收货地址：" label-width="300px">
						<el-radio-group v-model="ruleForm.IsOpenCustomerEditReceiveAddress">
							<el-radio :label="true">开启</el-radio>
							<el-radio :label="false">关闭</el-radio>
						</el-radio-group>
						<div style="font-size:14px;color:#999999;line-height:1.5">1.仅待付款、待发货且配送方式为快递发货的订单可修改收货地址</div>
						<div style="font-size:14px;color:#999999;line-height:1.5">2.待发货订单在发货前仅可修改1次;分销采购单仅可在待付款时可修改</div>
						<div style="font-size:14px;color:#999999;line-height:1.5">3.新收货地址的运费与原收货地址运费不一致，不支持修改</div>
					</el-form-item>
					
					<el-form-item label="退款原因：" label-width="300px" prop="RefundReason" :rules="rules.RefundReason">
						<div style="font-size:14px;color:#999999;">针对退货退款，或已收到货仅退款的类型，设置退款原因；最多添加15个原因</div>
						<div v-for="(v,i) in ruleForm.RefundReason" :key="i" style="margin-bottom:10px" class="flexRow">
							<span>{{i+1}}.</span>
							<el-form-item label="" label-width="0px" class="formErr-left10"  style="margin-bottom:10px" :key="v.key"
							  :prop="'RefundReason.'+i+'.value'" :rules="rules.RefundReasonvalue">
								<el-input v-model.trim="v.value" style="width:300px;margin:0px 10px" placeholder="请输入可选择的原因，最多30个字" maxlength="30"></el-input>
							</el-form-item>
							<el-button type="text" @click="delreason(i)" v-if="ruleForm.RefundReason.length>1">删除</el-button>
						</div>
						<el-button type="text" v-if="ruleForm.RefundReason&&ruleForm.RefundReason.length<15" @click="pushreason">+ 添加原因</el-button>
					</el-form-item>
				</div>
			</el-card>


			<!-- <el-card class="box-card" style="margin: 10px 0;">
				<div slot="header" class="clearfix">
					<span>支付设置</span>
					<div style="float:right" v-show="ruleForm.PayBy==1">
						<el-button type="text" style="margin-right:10px;" @click="openNewWindow(3)">支付申请教程</el-button>
						<el-button type="text" @click="openNewWindow(4)">支付设置教程</el-button>
					</div>
					<div style="float:right" v-show="ruleForm.PayBy==5">
						<el-button type="text" style="margin-right:10px;" @click="openNewWindow(0)">支付申请教程</el-button>
						<el-button type="text" @click="openNewWindow(1)">支付设置教程</el-button>
					</div>
				</div>
				<el-form-item label="支付方式：" v-if="ruleForm.IsOpen498Pay">
					<el-radio v-model="ruleForm.PayBy" :label="1">微信支付</el-radio>
					<el-radio v-model="ruleForm.PayBy" :label="5">第三方支付</el-radio>
				</el-form-item>
				<div v-if='ruleForm.PayBy==1'>
					<el-form-item label="微信支付商户号：">
						<el-input v-model="ruleForm.WxPayMallNo" 
							placeholder="请输入与小程序绑定的微信支付商户号" clearable 
							onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) );"
						 	onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this);this.v();" 
							onblur="this.v();this.v();"
						 	style="width:300px;">
						</el-input>
						<el-button type="text"  style="margin-left: 10px;" @click="openNewWindow(7)">去注册微信支付</el-button>
					</el-form-item>
					<el-form-item label="微信支付API密钥：" prop="WxPayApiSgin">
						<el-input v-model="ruleForm.WxPayApiSgin" placeholder="请输入微信支付API密钥" clearable 
							@input="ruleForm.WxPayApiSgin=apiInput(ruleForm.WxPayApiSgin)"
							style="width:300px;">
						</el-input>
					</el-form-item>
					<el-form-item label="微信退款证书：">
						<div v-if="ruleForm.WxRefundCertImgUrl">退款证书已上传</div>
						<el-upload class="upload-demo" :action="upLoadFile" :show-file-list="false" :on-success="handleAvatarSuccessBook"
						 style="display: inline-block;marin-left:10px;"> -->
			<!-- 如果没有退款证书 -->
			<!-- <el-button size="small" type="primary" v-if="!ruleForm.WxRefundCertImgUrl" plain>上传退款证书</el-button> -->
			<!-- 如果有退款证书 -->
			<!-- <el-button size="small" type="primary" v-if="ruleForm.WxRefundCertImgUrl" plain>更换退款证书</el-button>
						</el-upload>
					</el-form-item>
				</div>
				<div v-else>
					<el-form-item label="支付商户号：" v-if='ruleForm.PayBy==5'>
						<el-input v-model="ruleForm.Pay498MchId" placeholder="请输入第三方支付商户号" maxlength="500"></el-input>
						<el-button type="text" style="margin-left: 10px;" v-if='!ruleForm.IsApplyPay' @click="openNewWindow(8)">去注册</el-button>
						<el-button type="text" style="margin-left: 10px;" v-if='ruleForm.IsApplyPay' @click="openNewWindow(2)">查看注册结果</el-button>
					</el-form-item>

					<el-form-item label="支付秘钥：" v-if='ruleForm.PayBy==5'>
						<el-input v-model="ruleForm.Pay498PublicKey" placeholder="请输入第三方支付秘钥" maxlength="500"></el-input>
					</el-form-item>
				</div>
			</el-card> -->

			<el-card class="box-card payMoneys-with">
				<div slot="header" class="clearfix"><span>商详页设置</span></div>
				<el-form-item label="商品详情页，推手开通入口：" label-width="200px">
					<el-radio v-model="ruleForm.IsOpenDistributorToWapProductDetail" :label="1">开启</el-radio>
					<el-radio v-model="ruleForm.IsOpenDistributorToWapProductDetail" :label="0">关闭</el-radio>
					<div style="font-size: 14px;color:#999;">开启后，将会在商品详情页显示推手开通入口，
						<span style="color:#409EFF;cursor: pointer;" @click="lookShow(1)">查看示例</span>
					</div>
				</el-form-item>

				<el-form-item label="推手入口展示商品佣金：" label-width="200px"
					v-show="ruleForm.IsOpenDistributorToWapProductDetail">
					<el-radio v-model="ruleForm.IsDisplayProductSellReward" :label="true">开启</el-radio>
					<el-radio v-model="ruleForm.IsDisplayProductSellReward" :label="false">关闭</el-radio>
				</el-form-item>

				<el-form-item label="商品详情页,会员开通入口：" label-width="200px">
					<div>
						<el-radio v-model="ruleForm.IsOpenMemberToWapProductDetail" :label="true">开启</el-radio>
						<el-radio v-model="ruleForm.IsOpenMemberToWapProductDetail" :label="false">关闭</el-radio>
					</div>
					<div style="font-size:14px;color:#909399;">开启后，将会在商品详情页显示会员开通入口，引导客户成为会员</div>
				</el-form-item>

				<el-form-item label="会员开通成功跳转页面：" label-width="200px" v-show="ruleForm.IsOpenMemberToWapProductDetail">
					<div @click="vipLinkVisible=true" :closable='ruleForm.PageName!=""' class="flex flex-align-center color-409eff"
						@close="closeVipLink" style="cursor:pointer">
						<span v-show="!ruleForm.PageName">请选择链接</span>
						<span v-show="ruleForm.PageName">已选：{{ruleForm.PageName}}</span>
						<i class="el-icon-arrow-down" style="margin-left:5px"></i>
						<i class="el-icon-close" style="margin-left:5px" v-if="ruleForm.PagePath" @click.stop="clearLink"></i>
					</div>
					<div style="font-size:14px;color:#909399;">选择链接页面，会员开通成功后自动跳转至该页面;为空时默认跳转至会员权益页</div>
				</el-form-item>

				<el-form-item label="商品详情页，成交记录展示：" label-width="200px">
					<el-radio v-model="ruleForm.IsOpenRecordToWapProductDetail" :label="1">开启</el-radio>
					<el-radio v-model="ruleForm.IsOpenRecordToWapProductDetail" :label="0">关闭</el-radio>
					<div style="font-size: 14px;color:#999;">开启后，将会在商品详情页显示成交记录，
						<span style="color:#409EFF;cursor: pointer;" @click="lookShow(2)">查看示例</span>
					</div>
				</el-form-item>

				<el-form-item label="商品详情页，公共图配置：" label-width="200px">
					<el-checkbox v-model="ruleForm.IsOpenProductShufflingPublicImg"
						@change="handleCheckShufflingPublic">商品轮播公共图</el-checkbox>
					<div v-if='ruleForm.IsOpenProductShufflingPublicImg'>
						<el-radio v-model="ruleForm.PublicImgLocation" :label="0">展示在第一张</el-radio>
						<el-radio v-model="ruleForm.PublicImgLocation" :label="1">展示在最后一张</el-radio>
						<br>
						<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false"
							:on-success="handleGoodsCarouselSuccess" list-type="picture-card"
							style="display: inline-block;">
							<img v-if="ruleForm.ProductShufflingPublicImgUrl"
								:src="imgUrl + ruleForm.ProductShufflingPublicImgUrl" class="upload-avatar" />
							<i v-else class="el-icon-plus"></i>
						</el-upload>
					</div>
					<br>
					<el-checkbox v-model="ruleForm.IsOpenProductDetailPublicImg" @change="handleCheckDetailPublic">
						商品详情图公共图</el-checkbox>
					<div style="font-size: 14px;color:#999;">上传后，会显示在商品详情图最后一张</div>
					<el-upload v-if='ruleForm.IsOpenProductDetailPublicImg' class="avatar-uploader" :action="imgApi"
						:show-file-list="false" :on-success="handleGoodsDetaileSuccess" list-type="picture-card"
						style="display: inline-block;">
						<img v-if="ruleForm.ProductDetailPublicImgUrl"
							:src="imgUrl + ruleForm.ProductDetailPublicImgUrl" class="upload-avatar" />
						<i v-else class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="商详页库存显示：" :required="true">
					<!-- <div class="filter-item"><span style="color:red;margin-right: 4px;font-size: 12px;">*</span>商详页库存显示：</div> -->
					<el-radio v-model="ruleForm.WapProductDetailStockDisplayType" :label="0">始终显示</el-radio>
					<div>
						<!--  style="margin-left:130px" -->
						<el-radio v-model="ruleForm.WapProductDetailStockDisplayType" :label="1">始终不显示</el-radio></br>
						<el-radio v-model="ruleForm.WapProductDetailStockDisplayType" :label="2">
							<div class="filter-container" style="display: inline-block;">
								<div class="filter-item">库存小于(含)</div>
								<div class="filter-item">
									<el-form-item label="" label-width="0"
										:prop="ruleForm.WapProductDetailStockDisplayType==2?'WapProductDetailStockDisplayMax':'nocheck'"
										:rules='ruleForm.WapProductDetailStockDisplayType==2?rules.WapProductDetailStockDisplayMax:rules.nocheck'>
										<el-input v-model="ruleForm.WapProductDetailStockDisplayMax" clearable
											onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) );"
											onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this);this.v();"
											onblur="this.v();this.v();" style="width:120px;margin:0 10px;">
										</el-input>
										件时显示
									</el-form-item>
								</div>
							</div>
						</el-radio>
					</div>
				</el-form-item>

				<el-form-item label="商详页店铺信息显示：" label-width="170" v-if="mallInfo.IsMoreShop">
					<el-radio v-model="ruleForm.WapProductDetailShopInfoDisplayType" :label="1">显示商城信息</el-radio>
					<el-radio v-model="ruleForm.WapProductDetailShopInfoDisplayType" :label="0">显示门店信息</el-radio>
				</el-form-item>

				<el-form-item label="商品分享卡片：" style="margin-bottom: 0px;">
					<el-radio v-model="ruleForm.ProductShareCardStyleType" :label="0">使用商品主图</el-radio>
					<el-radio v-model="ruleForm.ProductShareCardStyleType" :label="1">使用样式一</el-radio>
					<el-radio v-model="ruleForm.ProductShareCardStyleType" :label="2">使用样式二</el-radio>
				</el-form-item>
				<el-button @click='goodsShareVisiable=true' style="margin-left:150px" type="text">查看示例</el-button>

				<el-form-item label="商品分享海报头图：" style="margin-bottom: 0px;" class="poster-head-form">
					<div style="overflow:hidden;">
						<el-radio v-model="ruleForm.ProductShareHeadPicType" :label="0">使用默认头图</el-radio>
						<el-radio v-model="ruleForm.ProductShareHeadPicType" :label="1">自定义上传头图</el-radio>
						<div v-if="ruleForm.ProductShareHeadPicType">
							<div style="color:#909399;">图片建议尺寸宽750px，高144px;图片大小不超过200KB</div>
							<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false"
								:on-success="handlePosterHeadSuccess" list-type="picture-card"
								style="display: inline-block;">
								<img v-if="ruleForm.ProductShareHeadPic" :src="imgUrl + ruleForm.ProductShareHeadPic"
									class="upload-avatar" />
								<i v-else class="el-icon-plus"></i>
							</el-upload>
						</div>

						<div class="default-avatar" v-else>
							<div class="avatar" :style="{
									'--posterHeadColor':ruleForm.MainColor
								}" :posterHeadBrand='ruleForm.MallName'>
							</div>
						</div>
					</div>
				</el-form-item>

				<el-form-item label="商品评价：" style="margin-bottom: 0px;">
					<el-radio v-model="ruleForm.IsShowProductComment" :label="true">显示</el-radio>
					<el-radio v-model="ruleForm.IsShowProductComment" :label="false">隐藏</el-radio>
				</el-form-item>

				<el-form-item label="商品关联推荐模块：" style="margin-bottom: 0px;">
					<div style="overflow:hidden;">
						<el-radio v-model="ruleForm.IsShowProductRelatedRecommendation" :label="true"
							@change="ruleForm.ProductRelatedRecommendationTitle='相关推荐'">开启相关推荐</el-radio>
						<el-radio v-model="ruleForm.IsShowProductRelatedRecommendation" :label="false"
							@change="ruleForm.ProductRelatedRecommendationTitle=''">关闭相关推荐</el-radio>
						<template v-if="ruleForm.IsShowProductRelatedRecommendation">
							<div>
								<span>模块标题：</span>
								<el-input maxlength="4" :disabled='!ruleForm.IsShowProductRelatedRecommendation'
									v-model="ruleForm.ProductRelatedRecommendationTitle"
									style="width:200px;margin:0 10px;"></el-input>
							</div>
							<div>
								<span>推荐规则：</span>
								<br>
								<span style="color:#909399;">
									（1）优先展示商品管理中自定义选择的推荐商品；
									<el-button type="text" @click="openNewWindow(6)">点击此处去设置</el-button>
								</span>
								<br>
								<span
									style="color:#909399;">（2）根据商品首次上架时间以及综合计算消费者的浏览、加购、成交的行为权重进行排序；没有访问过商城的用户，根据商品本身的加购、销量、浏览量来排序。</span>
							</div>
						</template>

					</div>
				</el-form-item>

			</el-card>
			<el-card class="box-card payMoneys-with" style="margin:10px 0 10px 0">
				<div slot="header" class="clearfix"><span>提现规则</span></div>
				<div class="content">
					<div class="filter-container">
						<div class="filter-item"><span
								style="color:red;margin-right: 4px;font-size: 12px;">*</span>提现账户类型：</div>
						<div class="filter-item">
							<el-form-item label="" label-width='0' style="margin-top:20px"
								:prop="(ruleForm.IsOpenWithdrawalsToAlipay||ruleForm.IsOpenWithdrawalsToBankCard)?'nocheck':'IsOpenWithdrawalsToAlipay'">
								<el-checkbox v-model="ruleForm.IsOpenWithdrawalsToAlipay" @change="changeWithdrawals">
									提现到支付宝</el-checkbox>
								<el-checkbox v-model="ruleForm.IsOpenWithdrawalsToBankCard">提现到银行卡</el-checkbox>
							</el-form-item>
						</div>
						<div v-show="ruleForm.IsOpenWithdrawalsToAlipay&&!IsOpenAliEnterprisePay"
							class="jump-container">
							<i class="el-icon-warning-outline"></i>
							<span>温馨提醒：您的商城当前还未绑定企业支付宝账号，客户或店员申请提现至支付宝时，无法通过企业支付宝自动转账，因此审核通过前请确认已线下转账成功。
							</span>
							<el-button type='text' @click="openNewWindow(5)">如何开通企业支付宝自动转账？</el-button>
						</div>
					</div>

					<el-form-item label="申请提现时间：" :required="true">
						<el-radio v-model="ruleForm.WithdrawalsDateIsLimit" :label="0">不限</el-radio>
						<div class="filter-container">
							<el-radio v-model="ruleForm.WithdrawalsDateIsLimit" :label="1">

								<div class="filter-item">每月</div>
								<div class="filter-item">
									<el-form-item label="" label-width='0'
										:prop="ruleForm.WithdrawalsDateIsLimit==1?'WithdrawalsStartDateEveryMonth':'nocheck'"
										:rules='ruleForm.WithdrawalsDateIsLimit==1?rules.WithdrawalsStartDateEveryMonth:rules.nocheck'>
										<el-input v-model="ruleForm.WithdrawalsStartDateEveryMonth" clearable
											onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) );"
											onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this);this.v();"
											onblur="this.v();this.v();" style="width:120px;margin:0 10px;">
										</el-input>
									</el-form-item>
								</div>
								<div class="filter-item">
									号至
								</div>
								<div class="filter-item">
									<el-form-item label="" label-width='0'
										:prop="ruleForm.WithdrawalsDateIsLimit==1?'WithdrawalsEndDateEveryMonth':'nocheck'"
										:rules='ruleForm.WithdrawalsDateIsLimit==1?rules.WithdrawalsEndDateEveryMonth:rules.nocheck'>
										<el-input v-model="ruleForm.WithdrawalsEndDateEveryMonth" clearable
											onkeypress="return( /[\d]/.test(String.fromCharCode(event.keyCode) ) );"
											onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9-]+/,'');}).call(this);this.v();"
											onblur="this.v();this.v();" style="width:120px;margin:0 10px;">
										</el-input>号
									</el-form-item>
								</div>

							</el-radio>
						</div>
					</el-form-item>

					<el-form-item label="起提金额：" prop="WithdrawalsMinMoney">
						<el-input v-model="ruleForm.WithdrawalsMinMoney" style="width:120px;"
							onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);">
						</el-input>
						元起提
					</el-form-item>

					<el-form-item label="提现说明：">
						<el-form-item label="文本说明：" label-width="100px" class="nobold">
							<el-input v-model="ruleForm.WithdrawalsExplain" type="textarea" :rows="6"
								placeholder="请输入提现说明，最多500字" maxlength="500"></el-input>
						</el-form-item>
						<el-form-item label="图片说明：" label-width="100px" class="margin-top-20 nobold">
							<div class="flex flex-align-end">
								<el-upload class="avatar-uploader" :action="imgApi" :before-upload="beforeAvatarUploadWithdrawalsImgExplain"
									:show-file-list="false" :on-success="uploadWithdrawalsImgExplainSuc" list-type="picture-card"
									style="display: inline-block;">
									<img v-if="ruleForm.WithdrawalsImgExplain"
										:src="imgUrl + ruleForm.WithdrawalsImgExplain" class="upload-avatar" />
									<i v-else class="el-icon-plus"></i>
								</el-upload>
								<el-button type="text" class="margin-left-10" v-if="ruleForm.WithdrawalsImgExplain" @click="ruleForm.WithdrawalsImgExplain=''">清空图片</el-button>
							</div>
							<div class="font-14" style="color:#999999FF">图片建议尺寸宽750px；图片大小1M以内</div>
						</el-form-item>
					</el-form-item>
					<el-form-item label="提现须知与协议：">
					<div style="font-size: 14px;color:#999;">你可以在这里编辑提现相关注意事项等，配置后将会显示在提现账号绑定页，
						<span style="color:#409EFF;cursor: pointer;" @click="lookShow(3)">查看示例</span>
					</div>
					<div>协议标题：
						<el-input v-model="ruleForm.UserAgreementTitle" placeholder="最多输入20个字" maxlength='20' />
					</div>
					<div style='display:flex;margin-bottom:150px;'>协议内容：
						<quill-editor ref="myTextEditor" v-model="ruleForm.UserAgreementContent" :options="quillOption"
							style="margin-top:10px;margin-left:5px;width:750px;min-height: 200px;max-height:720px;">
						</quill-editor>
					</div>
				</el-form-item>

				</div>
			</el-card>

			<el-card class="box-card other-card" style="margin:10px 0">
				<div slot="header" class="clearfix"><span>推手模块设置</span></div>
				<el-form-item label="推手锁粉方式：">
					<div>
						<div>
							<el-radio v-model="ruleForm.DistributorLockFansType" :label="0">分享商城链接</el-radio>
							<div class = "grayfont">推手分享商城链接或商城小程序码，客户点击链接或识别码进入商城，与对应推手锁粉</div>
						</div>
						<div>
							<el-radio style="margin-left: 110px;" v-model="ruleForm.DistributorLockFansType" :label="1">下单+邀请码</el-radio>
							<div class = "grayfont">客户下单时填写推手邀请码
								<span style="color:#f56c6c">（助力活动、赠品领取除外）</span>
								，订单支付成功后根据填写的邀请码与对应推手锁粉</div>
						</div>
					</div>
				</el-form-item>
				<el-form-item label="推手锁粉有效期：" label-width="250px">
					<el-radio v-model="ruleForm.MemberFirstFromValidityType" :label="0" @change="changeValidPeriod">永久锁粉
					</el-radio>
					<div class="flex-box">
						<el-radio v-model="ruleForm.MemberFirstFromValidityType" :label="1" @change="changeValidPeriod">
							固定周期自动解绑，周期
							<el-form-item style="display:inline-block;" prop="ValidityDay1"
								:rules="ruleForm.MemberFirstFromValidityType==1 ? rules.ValidityDay1: rules.nocheck">
								<el-input :disabled='ruleForm.MemberFirstFromValidityType!=1' style="width:100px;"
									v-model="ruleForm.ValidityDay1"
									@input='ruleForm.ValidityDay1=validateInt(ruleForm.ValidityDay1)' />
							</el-form-item>
							<span>天</span>
						</el-radio>
					</div>
					<div class="flex-box">
						<el-radio v-model="ruleForm.MemberFirstFromValidityType" :label="2" @change="changeValidPeriod">
							<el-form-item style="display:inline-block;" prop="ValidityDay2"
								:rules="ruleForm.MemberFirstFromValidityType==2 ? rules.ValidityDay2: rules.nocheck">
								<el-input :disabled='ruleForm.MemberFirstFromValidityType!=2' style="width:100px;"
									v-model="ruleForm.ValidityDay2"
									@input='ruleForm.ValidityDay2=validateInt(ruleForm.ValidityDay2)' />
							</el-form-item>
							<span>天内未访问锁粉人带参链接/二维码，自动解绑（若访问将自动延长有效期）</span>
						</el-radio>
					</div>
				</el-form-item>
				<el-form-item label="推手招募机制：">
					<el-radio v-model="ruleForm.RecruitmentType" :label="0">人人分销</el-radio>
					<el-radio v-model="ruleForm.RecruitmentType" :label="1">邀请码机制</el-radio>
					<div class = "grayfont">人人分销机制：所有客户进入商城后，均可直接购买授权礼包商品，成为推手；初期创建的商城建议使用该配置，帮助商城快速发展首批推手</div>
					<div class = "grayfont">邀请码机制：客户进入商城后，若想购买授权礼包商品成为推手，需要填入现有推手的邀请码才可继续购买</div>
				</el-form-item>
				<el-form-item label="推手自动降级：">
					<el-radio v-model="ruleForm.IsAutoDistributorDemotion" :label="true"
						:disabled="isAgentModel == 1 ||isAgentModel == 2 ">开启</el-radio>
					<el-radio v-model="ruleForm.IsAutoDistributorDemotion" :label="false"
						:disabled="isAgentModel == 1 ||isAgentModel == 2 ">关闭</el-radio>
					<div class = "grayfont">开启后，推手若未达到当前级别的晋升门槛，将自动降级</div>
				</el-form-item>
				<template v-if="ruleForm.IsQiDianGiveSellReward">
           <el-form-item label="推手自购商品，直接抵扣收益：" >
					  <el-radio v-model="ruleForm.IsOpenDistributorCommissionDeduct" :label="true">开启</el-radio>
					  <el-radio v-model="ruleForm.IsOpenDistributorCommissionDeduct" :label="false">关闭</el-radio>
					  <div class = "grayfont">开启后，推手自己在商城内购买商品，计算最终支付金额时，将自动扣减商品相关收益</div>
				   </el-form-item>
				</template>
				
				<el-form-item label="推手中心晋升进度查询入口：">
					<el-radio v-model="ruleForm.IsOpenDistributorPromotionSchedule" :label="true">显示</el-radio>
					<el-radio v-model="ruleForm.IsOpenDistributorPromotionSchedule" :label="false">隐藏</el-radio>
				</el-form-item>
				
			</el-card>

			<el-card class="box-card" style="margin:10px 0 120px 0">
				<div slot="header" class="clearfix"><span>其他设置</span></div>
				<el-form-item label="商城首页与自定义页面,分享图标：" prop="ShareIconImgUrl" label-width="250px">
					<div @mouseover="isShowmasking=true" @mouseleave="isShowmasking=false"
						style="height:150px;border:1px solid #fff">
						<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false"
							:on-success="shareIconSuccess" list-type="picture-card"
							style="display: inline-block;marin-left:10px;" :before-upload="beforeAvatarUploadshare">
							<div class="headIconImg">
								<img v-if="ruleForm.ShareIconImgUrl" :src="imgUrl + ruleForm.ShareIconImgUrl"
									class="upload-avatar" />
								<!-- 上面的蒙版 -->
								<div class="iconImgmasking" v-if="isShowmasking"></div>
								<div class="iconimgBox" v-if="isShowmasking">更改图片</div>
							</div>
							<i v-if="!ruleForm.ShareIconImgUrl" class="el-icon-plus"></i>
						</el-upload>
					</div>
				</el-form-item>

				<el-form-item label="已售罄商品：" label-width="250px">
					<el-radio v-model="ruleForm.IsDisplaySoldOut" :label="true">展示</el-radio>
					<el-radio v-model="ruleForm.IsDisplaySoldOut" :label="false">不展示</el-radio>
					<div style="font-size: 14px;color:#999;">展示后，售罄的商品会在商城内展示，并显示“已售罄”标记</div>
				</el-form-item>

				<el-form-item label="猜你喜欢商品：" label-width="250px">
					<el-radio v-model="ruleForm.IsDisplayPageGuessLike" :label="true">展示</el-radio>
					<el-radio v-model="ruleForm.IsDisplayPageGuessLike" :label="false">不展示</el-radio>
					<div style="font-size: 14px;color:#999;">选择不展示，商详页、订单详情页、购物车页、退款详情页、优惠券领取页面、商城装修猜你喜欢模块等均不展示猜你喜欢商品数据
					</div>
				</el-form-item>

				<!-- <el-form-item label="联系客服/联系顾问优先级：" label-width="250px" v-if="mallInfo.IsUseQyWeixin">
					<el-radio v-model="ruleForm.ContactPriority" :label="0">优先联系客服</el-radio>
					<el-radio v-model="ruleForm.ContactPriority" :label="1">优先联系顾问</el-radio>
					<div style="font-size: 14px;color:#999;">
						若配置优先联系客服，在商城首页或商品详情页，点击[客服]，客户自动分配给商城在线客服；若配置优先联系顾问，自动分配给客户的锁粉店员</div>
				</el-form-item> -->

				
				<!-- ComponentId=2：是美琳商户， 美琳不需要这个功能 -->
				<el-form-item label="自提订单支付场景:" label-width="250px"
					v-if="mallInfo.IsMoreShop && mallInfo.ComponentId !== 2">
					<el-checkbox v-model="ruleForm.IsOpenPickUpOrderOnlinePay" :label='true'>线上商城</el-checkbox>
					<el-checkbox v-model="ruleForm.IsOpenPickUpOrderOfflinePay" :label='true'>线下门店</el-checkbox>
				</el-form-item>

				<el-form-item label="热门搜索：" label-width="250px">
					<el-input class="input-new-tag" v-model="hotSearchKeyword" placeholder="最多添加9个热门搜索" maxlength="10"
						:disable-transitions="false" :disabled='ruleForm.HotSearchKeywords.length>8' style="width:350px"
						@keyup.enter.native="handleInputConfirm">
						<el-button slot="append" @click='handleInputConfirm'
							:disabled='ruleForm.HotSearchKeywords.length>8'>添加</el-button>
					</el-input>
					<div>
						<el-tag :key="item+index" v-for="(item,index) in ruleForm.HotSearchKeywords" closable
							style="margin-right:5px" :disable-transitions="true" @close="handleClose(item)">
							{{item}}
						</el-tag>
					</div>
				</el-form-item>
				<el-form-item label="商品添加成功,同时创建素材:" label-width="250px">
					<!-- AddProductIsSyncProductMaterial  -->
					<el-radio v-model="ruleForm.AddProductIsSyncProductMaterial" :label="true">开启</el-radio>
					<el-radio v-model="ruleForm.AddProductIsSyncProductMaterial" :label="false">关闭</el-radio>
					<div style="font-size: 14px;color:#999;">
						开启后,商品添加成功，自动将商品图片和详情图作为素材上传至对应商品素材库,
						其中,素材描述使用商品名称+商品卖点</div>
				</el-form-item>
				<el-form-item label="门店库存管理模式：" label-width="250px">
					<el-radio-group v-model="ruleForm.ShopProductStockModel">
						<el-radio :label="0">共享商城库存</el-radio>
						<el-radio :label="1">门店独立库存</el-radio>
					</el-radio-group>
					<div class="grayfont" style="margin-left:0px">共享商城库存：客户进入小程序商城下单，选择任一种配送服务（快递发货、到店自提、同城配送），系统仅验证商品管理中维护的商品库存</div>
					<div class="grayfont" style="margin-left:0px">门店独立库存：选中该模式，客户进入小程序商城下单，若门店中对应商品库存不足，客户将无法选择到店自提或同城配送服务；商家可前往销售渠道→门店管理，管理各门店的商品库存</div>
				</el-form-item>
			</el-card>
		</el-form>
		<el-card class="box-card footer">
			<el-button style="width:240px;position: relative;bottom: 20px;right: 190px;" type="primary"
				@click="saveSupiler('ruleForm')">保存</el-button>
		</el-card>

		<!-- 查看示例弹框 -->
		<el-dialog :visible.sync="lookVisable" title="查看示例" width="570px" :show-close="false">
			<div style="overflow-y:auto;max-height:600px;max-width:570px" v-if="imgType!=4">
				<img :src="lookPicture" alt="" style="width:100%">
			</div>
			<div class="flex flex-justify-center" v-else>
				<img :src="lookPicture" alt="" style="width:320px;height:540px">
			</div>
		</el-dialog>

		<!--商品分享卡片 查看示例 -->
		<el-dialog :visible.sync="goodsShareVisiable" width="950px" title="查看示例" :show-close="false">
			<div style='display:flex;flex-deriction:row;justify-content:space-between;'>
				<div>
					<div>商品主图</div>
					<img src="https://cdn.dkycn.cn/images/melyshop/set-good-share-pic1.png" alt=""
						style='width:280px;object-fit:contain;margin-top: 5px;'>
				</div>
				<div>
					<div>样式一</div>
					<img src="https://cdn.dkycn.cn/images/melyshop/set-good-share-pic2.png" alt=""
						style='width:280px;object-fit:contain;margin-top: 5px;'>
				</div>
				<div>
					<div>样式二</div>
					<img src="https://cdn.dkycn.cn/images/melyshop/set-good-share-pic3.png" alt=""
						style='width:280px;object-fit:contain;margin-top: 5px;'>
				</div>
			</div>
		</el-dialog>

		<!-- 选择的页面弹框 -->
		<link-modal :showLiveTab="true" :visible="vipLinkVisible" @change="vipLinkChanged"	@cancel="vipLinkVisible=false"></link-modal>

		<!-- 管理限售区域 -->
		<el-dialog :visible.sync="areaShow" title="选择全店限售区域" width="900px" v-if="areaShow">
			<select-ship :defaultData='ruleForm.RestrictedAreas' @getCheckedArea='getCheckedArea' :excludeData='excludeData'></select-ship>
		</el-dialog>

	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import axios from 'axios';
	import config from '@/config/index';
	import ImageDrag from '@/components/uploadImginbatch';
	import quillConfig from '@/components/quill-config.js'
	import selectShip from './components/selectShipDialog.vue';
	import {
		mallDataInfo
	} from '@/api/TurnTomySelf'
	import {
		savemallbaseinfo,
		mallbaseinfo
	} from '@/api/wyUsedInterface.js';
	import {
		productGroupList
	} from "@/api/goods"
	import linkModal from '@/views/components/linkModal';
	export default {
		components: {
			linkModal,
			selectShip
		},
		computed: {
			...mapGetters([
				'mallInfo'
			]),
		},
		data() {
			var checkno = (rule, value, callback) => {
				return callback();
			};
			var OrderAutoCancelToMinutesCheck = (rule, value, callback) => {

				if (value > 1440 || value < 20) {
					return callback(new Error('时间请设置在20分钟至1440分钟之间'));
				} else {
					return callback();
				}
			};
			var receivetimeCheck = (rule, value, callback) => {

				if (value > 31 || value < 1) {
					return callback(new Error('时间请设置在1天至31天之间'));
				} else {
					return callback();
				}
			};
			var agreenTimeCheck = (rule, value, callback) => {

				if (value > 31 || value < 7) {
					return callback(new Error('时间请设置在7天至31天之间'));
				} else {
					return callback();
				}
			};
			var OrderSupportDayCheck = (rule, value, callback) => {
				if (!value || value > 15 || value < 1) {
					return callback(new Error('时间请设置在1天至15天之间，整数'));
				} else {
					return callback();
				}
			};
			var aciveTimeCheck = (rule, value, callback) => {

				if (value > 31 || value < 7) {
					return callback(new Error('时间请设置在7天至31天之间'));
				} else {
					return callback();
				}
			};
			var stockNumberCheck = (rule, value, callback) => {
				if (value <= 0 || (!value)) {
					return callback(new Error('库存须大于0'));
				} else if (value > 1000) {
					return callback(new Error('库存须不高于1000'));
				} else {
					return callback();
				}
			};
			var withdrawPayCheck = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('至少选择一种账户类型'));
				} else {
					return callback();
				}

			};
			var minTimesCheck = (rule, value, callback) => {

				if (value > 28 || value < 1) {
					return callback(new Error('时间须在1号至28号之间'));
				} else if (value > this.ruleForm.WithdrawalsEndDateEveryMonth) {
					return callback(new Error('开始时间须小于等于结束时间 '));
				} else {
					return callback();
				}
			};
			var maxTimesCheck = (rule, value, callback) => {
				if (value > 28 || value < 1) {
					return callback(new Error('时间须在1号至28号之间'));
				} else {
					return callback();
				}
			};

			var initialAmountCheck = (rule, value, callback) => {
				if (value > 9999 || value < 1) {
					return callback(new Error('金额须在1元至9999元之间'));
				} else {
					return callback();
				}
			};
			var checkValidityDay1 = (rule, value, callback) => {
				if (this.ruleForm.ValidityDay1 > 365 || this.ruleForm.ValidityDay1 < 1) {
					return callback(new Error('周期天数请设置在1-365天之间，整数'));
				} else {
					return callback();
				}
			};
			var checkValidityDay2 = (rule, value, callback) => {
				if (this.ruleForm.ValidityDay2 > 365 || this.ruleForm.ValidityDay2 < 1) {
					return callback(new Error('周期天数请设置在1-365天之间，整数'));
				} else {
					return callback();
				}
			};
			var checkWxPayApiSgin = (rule, value, callback) => {
				if (value && value.length != 32) {
					return callback(new Error('API密钥为32位字符，支持数字/大小写字母'));
				} else {
					return callback();
				}
			};
			var checkName = (rule, value, callback) => {
				// console.log(value.length)
				if(value.length>6){
					callback(new Error('留言名称不能多于6个字'))
				}else{
					callback()
				}
			};
			var checkRefundReasonvalue = (rule, value, callback) => {
				if(!value){
					callback(new Error('请输入原因'))
				}else{
					callback()
				}
			};

			var checkIsOpenPickUpOrderAutoRefund = (rule, value, callback) => {
				if(value&&!this.ruleForm.IsOpenPickUpOrdinaryOrderAutoRefund&&!this.ruleForm.IsOpenPickUpActivityOrderAutoRefund){
					callback(new Error('已开启自提单自动退款，请完善该项配置'))
				}else{
					callback()
				}
			};
			var checktwoDecimal = (rule, value, callback) => {
				let two = /^\d+(\.\d{1,2})?$/
				if(value<0.01||value>100||!two.test(value)){
					callback(new Error('金额请设置在0.01~100之间，2位小数'))
				}else{
					callback()
				}
			};
			var checkdays31 = (rule, value, callback) => {
				if(!value||value<1||value>31){
					callback(new Error('天数请设置在1~31天之间，整数'))
				}else{
					callback()
				}
			};
			return {
				hotSearchKeyword: '',
				quillOption: quillConfig,
				lookVisable: false,
				goodsShareVisiable: false,
				lookPicture: '',
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				upLoadFile: config.UPLOAD_ZIP,
				loading: false,
				props: {
					label: 'name',
					value: 'id',
					children: 'child'
				},
				ruleForm: {
					AddProductIsSyncProductMaterial:false,//商品设置新增
					ShopProductStockModel:0,
					IsRefundToShop:false,
					RestrictedAreas:[],
					MainCategory:null,
					SpecialProdctCategorys:[],

					MallName: '',
					MainColor: '#ff547b',
					ContactPhone: '',
					MallLogoUrl: '',
					MallButtomLogoUrl: '',
					ReceiveName: '',
					ReceivePhone: '',
					ReceiveAddress: '',
					AreaCode: '',
					Welcom: '',
					AddressArr: [],
					ReceiveProvinceId: 0,
					ReceiveCityId: 0,
					ReceiveAreaId: 0,
					ReceiveProvince: '',
					ReceiveCity: '',
					ReceiveArea: '',
					PayBy: 1,
					Pay498MchId: '',
					Pay498PublicKey: '',
					IsOpenDistributorToWapProductDetail: 0,
					IsDisplayProductSellReward: false,
					IsOpenRecordToWapProductDetail: 0,
					PublicImgLocation: 0,
					ProductShufflingPublicImgUrl: '',
					IsOpenProductDetailPublicImg: false,
					ProductDetailPublicImgUrl: '',
					IsOpenProductShufflingPublicImg: false,
					IsDisplaySoldOut: false,
					IsDisplayPageGuessLike: true,
					// ContactPriority: 0,
					IsOpenDistributorCommissionDeduct: false,
					IsAutoDistributorDemotion: false,
					UserAgreementTitle: '',
					UserAgreementContent: '',

					WaitPayOrderCancelTimeShow: 20,
					AutomaticReceivingTimeToSecondShow: 7,
					IsOpenAutoConsentSupportRequest: 1,
					AutoConsentSupportRequestTimeShow: 7,
					IsOpenMallAutomaticReceiving: 1,
					IsOpenSendOrderSupportFreight: false,
					SpellGroupOrderSupportType: 0,
					SpellGroupOrderSupportDay: 1,
					aciveType: 1,
					MallAutomaticReceivingTimeShow: 7,
					WxPayMallNo: '',
					WapProductDetailStockDisplayType: 1,
					WapProductDetailStockDisplayMax: "",
					WapProductDetailShopInfoDisplayType: 1,
					IsShowProductRelatedRecommendation: false,
					IsShowProductComment: true,
					ProductShareHeadPicType: 0,
					cashMethods: 0,
					IsOpenWithdrawalsToAlipay: true,
					IsOpenWithdrawalsToBankCard: false,
					WithdrawalsStartDateEveryMonth: '',
					WithdrawalsEndDateEveryMonth: '',
					WithdrawalsDateIsLimit: 0,
					WithdrawalsMinMoney: 1,
					WithdrawalsExplain: '',
					ShareIconImgUrl: '',
					WxRefundCertImgUrl: '',
					IsOpenDistributorBuyProductSubReward: true,
					MemberFirstFromValidityType: 0,
					ValidityDay1: null,
					ValidityDay2: null,
					IsOpenMemberToWapProductDetail: true,
					PageName: null,
					MenuType: null,
					PagePath: null,
					OtherData: null,
					IsOpenPickUpOrderOnlinePay: true,
					IsOpenPickUpOrderOfflinePay: false,
					WxPayApiSgin: null,
					HotSearchKeywords: [],

					RecruitmentType:0,

					OrderRemarkConfig:[],

					DistributorLockFansType:0,
					IsRefundCancelOrderPoint:false,
					IsOpenCustomerEditReceiveAddress:false,
					RefundReason:[],
					WithdrawalsImgExplain:'',

					IsOpenPickUpOrderAutoRefund:false,
					IsOpenPickUpOrdinaryOrderAutoRefund:false,
					IsOpenPickUpActivityOrderAutoRefund:false,
					PickUpOrdinaryOrderAutoRefundPaidMoney:1,
					PickUpActivityOrderAutoRefundPaidMoney:1,
					PickUpOrdinaryOrderAutoRefundInStoreDay:7,
					PickUpActivityOrderAutoRefundInStoreDay:7,
					IsOpenPickUpOrderTakeGoodsNotice:false,
					PickUpOrderTakeGoodsNoticeNoEnterStoreDay:1,
				},
				currentCount: '',
				rules: {
					MallName: [{
						required: true,
						message: '请输入商城名称',
						trigger: 'blur'
					},{
						required: true,
						max:12,
						min:2,
						message: '商城名称请输入在2~12字之间',
						trigger: 'blur'
					}],
					SmsSgin: [{
						required: true,
						message: '请输入短信签名',
						trigger: 'blur'
					},{
						required: true,
						max:12,
						min:2,
						message: '短信签名请输入在2~12字之间',
						trigger: 'blur'
					}],
					MallButtomLogoUrl: [{
						required: true,
						message: '请选择商城底部LOGO',
						trigger: 'change'
					}],
					WaitPayOrderCancelTimeShow: [{
						required: true,
						validator: OrderAutoCancelToMinutesCheck,
						trigger: 'blur'
					}],
					AutomaticReceivingTimeToSecondShow: [{
						required: true,
						validator: receivetimeCheck,
						trigger: 'blur'
					}],
					AutoConsentSupportRequestTimeShow: [{
						required: true,
						validator: agreenTimeCheck,
						trigger: 'blur'
					}],
					SpellGroupOrderSupportDay: [{
						required: true,
						validator: OrderSupportDayCheck,
						trigger: 'blur'
					}],
					nocheck: [{
						validator: checkno,
						trigger: 'change'
					}],
					MallAutomaticReceivingTimeShow: [{
						required: true,
						validator: aciveTimeCheck,
						trigger: 'blur'
					}],
					IsOpenSendOrderSupportFreight: [{
						required: true,
						trigger: 'change'
					}],
					WapProductDetailStockDisplayMax: [{
						required: true,
						validator: stockNumberCheck,
						trigger: 'blur'
					}],
					cashMethods: [{
						required: true,
						message: '至少选择一种账户类型',
						trigger: 'change'
					}],
					IsOpenWithdrawalsToAlipay: [{
						required: true,
						validator: withdrawPayCheck,
						trigger: 'change'
					}],
					WithdrawalsStartDateEveryMonth: [{
						required: true,
						validator: minTimesCheck,
						trigger: 'blur'
					}],
					WithdrawalsEndDateEveryMonth: [{
						required: true,
						validator: maxTimesCheck,
						trigger: 'blur'
					}],
					WithdrawalsMinMoney: [{
						required: true,
						validator: initialAmountCheck,
						trigger: 'blur'
					}],
					IsOpenMallAutomaticReceiving: [{
						required: true,
						message: '是否开启商家自动确认时间',
						trigger: 'change'
					}],
					ValidityDay1: [{
						required: true,
						validator: checkValidityDay1,
						trigger: 'blur'
					}],
					ValidityDay2: [{
						required: true,
						validator: checkValidityDay2,
						trigger: 'blur'
					}],
					WxPayApiSgin: [{
						validator: checkWxPayApiSgin,
						trigger: 'blur'
					}],
					Name: [{
						validator: checkName,
						trigger: 'blur'
					}],
					RefundReason:[{
						required:true,
						message: '请输入原因',
						trigger: 'blur'
					}],
					RefundReasonvalue: [{
						validator: checkRefundReasonvalue,
						trigger: 'blur'
					}],

					IsOpenPickUpOrderAutoRefund:[{
						required:true,
						validator: checkIsOpenPickUpOrderAutoRefund,
						trigger: 'blur'
					}],
					twoDecimal:[{
						validator: checktwoDecimal,
						trigger: 'blur'
					}],
					days31:[{
						validator: checkdays31,
						trigger: 'blur'
					}]
				},
				dataList: [],

				isShowmasking: false,
				IsOpenAliEnterprisePay: false,

				isAgentModel: 0,

				vipLinkVisible: false,
				OptionListStr:'',
				areaShow:false,
				areaList:[],
				showAreaIds:[],
				excludeData:[],

				MainCategorylist:[
					{value:3,label:'日用百货'},
					{value:6,label:'水果生鲜'},
					{value:16,label:'服饰箱包'},
					{value:21,label:'食品小吃'},
					{value:33,label:'商超便利'},
					{value:40,label:'烧烤小龙虾'},
					{value:47,label:'烟酒'},
					{value:56,label:'母婴用品'},
					{value:57,label:'美妆用品'},
					// {value:59,label:'其他'},
				],

				SpecialProdctCategoryslist:[],
				imgType:1,
			};
		},

		beforeMount() {
			// console.log(this.mallInfo, '1212')
			// console.log(this.mallInfo.MallId)
			this.getclassity()
			this.getDataList();
			this.getData()
			
		},
		mounted() {
			this.isAgentModel = window.localStorage.getItem('mlmzDistributionModel')
			this.$refs.myTextEditor.quill.enable(false);
			this.mallDataInfo()
		},
		// updated() {
		// 	window.scroll(0, 0);
		// },

		methods: {
			clearLink(){
				this.ruleForm.PageName = ''
				this.ruleForm.MenuType = ''
				this.ruleForm.PagePath = ''
			},
			beforeAvatarUploadWithdrawalsImgExplain(file){
				let list = ['image/jpeg','image/jpg','image/png']
				if(!list.includes(file.type)){
					this.$message.error('请选择jpg,png格式上传');
					return false
				}
				const isLt1m = file.size / 1024 < 1024;
				if (!isLt1m) {
					this.$message({
						type: 'error',
						message: '图片大小请控制在1M以内',
						showClose: true
					})
					return false;
				}
			},
			uploadWithdrawalsImgExplainSuc(res,file){
				this.ruleForm.WithdrawalsImgExplain = res[0]
			},
			pushreason(){
				this.ruleForm.RefundReason.push({
					value:'',
					key:Date.now()
				})
			},
			delreason(e){
				this.ruleForm.RefundReason.splice(e,1)
			},
			async getclassity(){
				let res = await productGroupList()
				this.SpecialProdctCategoryslist = res.Result
			},
			closeTag(i){
				this.ruleForm.RestrictedAreas.splice(i,1)
				// this.showAreaIds.splice(i,1)
			},
			getCheckedArea(ids,names){
				// console.log(ids,names)
				// this.showAreaIds = ids
				this.ruleForm.RestrictedAreas = names
				this.areaShow = false
			},
			noteSave(i){
				// console.log(this.ruleForm.OrderRemarkConfig[i].OptionListStr.split('\n'))
				let list = this.ruleForm.OrderRemarkConfig[i].OptionListStr.replace(/\n/g, ',').split(',').filter(v=>v!='')
				if(list.length>30){
					this.$message.error('最多可添加30个选项')
					return 
				}else{
					this.ruleForm.OrderRemarkConfig[i].OptionList = list
					this.$nextTick(()=>{
						this.$refs.notePopover[i].doClose()
					})
				}
				// console.log(list)
				// console.log(this.ruleForm.OrderRemarkConfig[i].OptionList )
			},
			delNote(i){
				this.ruleForm.OrderRemarkConfig.splice(i,1)
			},
			noteSelectChange(e,i){
				// console.log(this.ruleForm.OrderRemarkConfig[i])
				this.ruleForm.OrderRemarkConfig[i].type = e
				this.$forceUpdate()
			},
			pushNote(){
				this.ruleForm.OrderRemarkConfig.push({
					Name :'留言'+(this.ruleForm.OrderRemarkConfig.length+1),
					ConfigType:0,
					OptionList:[],
					OptionListStr:'',
				})
				this.$forceUpdate()
			},
			popShow(i){
				this.OptionListStr = this.ruleForm.OrderRemarkConfig[i].OptionListStr
				// console.log(this.OptionListStr,1)
			},
			cancelPopover(i){
				// console.log(this.OptionListStr,2)
				this.ruleForm.OrderRemarkConfig[i].OptionListStr = this.OptionListStr
				// this.OptionListStr = ''
				this.$nextTick(()=>{
					this.$nextTick(()=>{
						this.$refs.notePopover[i].doClose()
					})
				})
				this.$forceUpdate()
			},
			handleInputConfirm() {
				if (this.hotSearchKeyword) {
					this.ruleForm.HotSearchKeywords.push(this.hotSearchKeyword);
				}
				this.hotSearchKeyword = '';
			},
			handleClose(tag) {
				this.ruleForm.HotSearchKeywords.splice(this.ruleForm.HotSearchKeywords.indexOf(tag), 1);
			},
			apiInput(val) {
				let codeReg = new RegExp("[A-Za-z0-9]+"),
					len = val.length,
					str = '';
				for (var i = 0; i < len; i++) {
					if (codeReg.test(val[i])) {
						str += val[i];
					}
				}
				return str
			},
			selectVipLink() {
				this.vipLinkVisible = true
			},
			closeVipLink() {
				this.ruleForm.MenuType = null
				this.ruleForm.PageName = ''
				this.ruleForm.PagePath = null
				this.ruleForm.OtherData = null
			},

			vipLinkChanged(record) {
				if (record.type == "classify") {
					this.ruleForm.PageName = record.data.GroupName
					this.ruleForm.MenuType = 1
					this.ruleForm.PagePath = 'pages/productGroup/productGroup?id=' + record.data.Id
				} else if (record.type == "functional") {
					this.ruleForm.PageName = record.data.name
					this.ruleForm.MenuType = 2
					// 全部商品
					if (record.data.id == 1) {
						this.ruleForm.PagePath = 'pages/index/index'
					} else if (record.data.id == 2) {
						this.ruleForm.PagePath = 'pages/allProduct/allProduct'
					} else if (record.data.id == 3) {
						// 个人中心
						this.ruleForm.PagePath = 'pages/personalCenter/mine/mine'
					} else if (record.data.id == 4) {
						// 购物车
						this.ruleForm.PagePath = 'pages/shoppingCart/shoppingCart'
					} else if (record.data.id == 5) {
						// 积分中心
						this.ruleForm.PagePath = 'pages/pointsCenter/pointsCenter'
					} else if (record.data.id == 6) {
						// 每日签到
						this.ruleForm.PagePath = 'pages/userSign/userSign'
					} else if (record.data.id == 7) {
						// 客服
						this.ruleForm.PagePath = 'pages/customerService/customerService'
					} else if (record.data.id == 8) {
						// 订单列表
						this.ruleForm.PagePath = 'pages/order/myOrder/myOrder'
					} else if (record.data.id == 9) {
						// 分销礼包
						this.ruleForm.PagePath = 'pages/invitation/invitationCard/invitationCard'
					} else if (record.data.id == 10) {
						// 成为会员
						this.ruleForm.PagePath = 'pageA/pages/openSuccess/openSuccess'
					} else if (record.data.id == 11) {
						// 助力直播列表
						this.ruleForm.PagePath = 'pageA/pages/live/live'
					} else if (record.data.id == 12) {
						// 扫码购
						this.ruleForm.PagePath = 'pageA/pages/scan/scan'
					} else if (record.data.id == 13) {
						// 充值中心
						this.ruleForm.PagePath = 'pageA/pages/balanceRecharge/balanceRecharge'
					} else if (record.data.id == 14) {
						// 群分享
						this.ruleForm.PagePath = 'pageA/pages/groupShare/groupShare'
					}else if (record.data.id == 15) {
						// 全部信息页
						this.ruleForm.PagePath = 'pageA/pages/information/information'
					}else if (record.data.id == 16) {
						// 短视频带货
						this.ruleForm.PagePath = 'pageC/pages/shortVideos/record'
					}else if (record.data.id == 17) {
						// 积分码
						this.ruleForm.PagePath = 'pageA/pages/receive-point/receive-point'
					}else if (record.data.id == 18) {
						// 舌诊
						this.ruleForm.PagePath = 'pageC/pages/tongueDiagnosis/home'
					}else if (record.data.id == 19) {
						// 企店即创主页
						this.ruleForm.PagePath = 'pageC/pages/AIGC/home'
					}
				} else if (record.type == "product") {
					this.ruleForm.PageName = record.data.Name
					this.ruleForm.MenuType = 3
					this.ruleForm.PagePath = 'pages/detail/detail?id=' + record.data.Id
				} else if (record.type == "custom") {
					this.ruleForm.PageName = record.data.PageName
					this.ruleForm.MenuType = 4
					this.ruleForm.PagePath = 'pages/cusPage/cusPage?id=' + record.data.Id
				} else if (record.type == 'truntable') {
					this.ruleForm.PageName = record.data.Name
					this.ruleForm.MenuType = 6
					this.ruleForm.PagePath = 'pageA/pages/activity/truntable/truntable?id=' + record.data.Id
				} else if (record.type == 'live') {
					this.ruleForm.PageName = record.data.RoomName
					this.ruleForm.MenuType = 5
					this.ruleForm.OtherData = record
					this.ruleForm.PagePath = 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=' +
						record.data.RoomId
				} else if (record.type == 'coupon') {
					this.ruleForm.PageName = record.data.CouponName
					this.ruleForm.MenuType = 7
					this.ruleForm.PagePath = 'pages/couponCenter/receive/receive?id=' + record.data.Id + '&uid=' + (record
						.data.uid || '')
				}
			},
			changeValidPeriod(e) {
				if (e == 1) {
					this.$refs['ruleForm'].validateField('ValidityDay2')
					this.ruleForm.ValidityDay2 = null
				} else {
					this.$refs['ruleForm'].validateField('ValidityDay1')
					this.ruleForm.ValidityDay1 = null
				}
				this.$forceUpdate()
			},
			// 验证整数
			validateInt(num) {
				num = num.replace(/^0*(0\.|[1-9])/, '$1');
				num = num.replace(/[^\d]/g, "");
				this.$forceUpdate()
				return num
			},
			changeWithdrawals() {
				if (this.ruleForm.IsOpenWithdrawalsToAlipay) {
					this.mallDataInfo()
				}
			},
			async mallDataInfo() {
				let result = await mallDataInfo()
				this.IsOpenAliEnterprisePay = result.Result.IsOpenAliEnterprisePay
			},
			openNewWindow(type) {
				let url = ''
				switch (type) {
					case 0: //第三方支付申请教程
						window.open('https://www.yuque.com/docs/share/5102bedb-c65a-457a-b9df-f89c92144fae?#')
						break;
					case 1: //第三方支付设置教程
						window.open('https://www.yuque.com/docs/share/cf866ced-fd7a-4b76-a91d-ef83739c0af3?#')
						break;
					case 2: //查看注册结果
						url = '/setting/payApplyResult'
						this.$common.openNewWindow(url)
						break;
					case 3: //微信支付申请教程
						window.open('https://www.yuque.com/docs/share/221eca69-3e5f-4d4a-a57d-4736260210a1?#')
						break;
					case 4: //微信支付设置教程
						window.open('https://www.yuque.com/docs/share/676d32ed-de53-462e-95c3-911b7f3c61c8?#')
						break;
					case 5: //如何开通企业支付宝自动转账？
						window.open('https://jusnn6k8al.feishu.cn/docx/HqiwdsEWBopmqAxlBNPczUbenYe')
						break;
					case 6: //点击此处去设置
						url = '/goods/goodsList'
						this.$common.openNewWindow(url)
						break;
					case 7: //去注册微信支付
						window.open('https://pay.weixin.qq.com/index.php/core/home/login?return_url=%2F')
						break;
					case 8: // 去注册
						window.localStorage.setItem('canEditSetinfos', true)
						window.localStorage.setItem('toPayResultFromPath', this.$route.path)

						url = '/setting/setPayApply'
						this.$common.openNewWindow(url)
						window.open('https://pay.weixin.qq.com/index.php/core/home/login?return_url=%2F')
						break;
					default:
						break;
				}
			},
			// 编辑时初始数据
			async getData() {

				try {
					let data = {}
					let res = await mallbaseinfo(data)
					this.ruleForm = res.Result
					// console.log(this.ruleForm.IsQiDianGiveSellReward)
					this.ruleForm.OrderRemarkConfig = res.Result.OrderRemarkConfig.map((v)=>{
						v.OptionListStr = v.OptionList.join('\n')
						return v
					})
					// console.log(this.ruleForm.OrderRemarkConfig)
					this.ruleForm.WapProductDetailShopInfoDisplayType = 1
					this.ruleForm.MainColor = res.Result.MainColor ? res.Result.MainColor : '#ff547b';
					this.ruleForm.IsOpenAutoConsentSupportRequest = this.ruleForm.IsOpenAutoConsentSupportRequest ? 1 :
						0
					this.ruleForm.IsOpenMallAutomaticReceiving = this.ruleForm.IsOpenMallAutomaticReceiving ? 1 : 0
					this.ruleForm.WithdrawalsDateIsLimit = this.ruleForm.WithdrawalsDateIsLimit ? 1 : 0
					this.ruleForm.AddressArr = [res.Result.ReceiveProvinceId + '', res.Result.ReceiveCityId + '', res
						.Result.ReceiveAreaId +
						''
					];
					this.ruleForm.PayBy = res.Result.PayBy
					this.ruleForm.IsOpenSendOrderSupportFreight = res.Result.IsOpenSendOrderSupportFreight
					this.ruleForm.SpellGroupOrderSupportType = res.Result.SpellGroupOrderSupportType
					this.ruleForm.SpellGroupOrderSupportDay = res.Result.SpellGroupOrderSupportDay
					this.ruleForm.Pay498MchId = res.Result.Pay498MchId
					this.ruleForm.Pay498PublicKey = res.Result.Pay498PublicKey
					this.ruleForm.IsOpenDistributorToWapProductDetail = res.Result
						.IsOpenDistributorToWapProductDetail ? 1 : 0
					this.ruleForm.ShareIconImgUrl = this.ruleForm.ShareIconImgUrl ? this.ruleForm.ShareIconImgUrl :
						'/image/20200424-600a0e17-9cf1-4e93-927f-4ef194c565be.png'

					this.ruleForm.IsOpenRecordToWapProductDetail = res.Result.IsOpenRecordToWapProductDetail ? 1 : 0


					if (this.ruleForm.MemberFirstFromValidityType === 0) {
						this.ruleForm.ValidityDay1 = this.ruleForm.ValidityDay2 = null

					} else if (this.ruleForm.MemberFirstFromValidityType === 1) {
						this.ruleForm.ValidityDay2 = null
						this.ruleForm.ValidityDay1 = this.ruleForm.MemberFirstFromValidityDay === 0 ? null : this
							.ruleForm.MemberFirstFromValidityDay
					} else {
						this.ruleForm.ValidityDay1 = null
						this.ruleForm.ValidityDay2 = this.ruleForm.MemberFirstFromValidityDay === 0 ? null : this
							.ruleForm.MemberFirstFromValidityDay
					}

					this.ruleForm.MainCategory = this.ruleForm.MainCategory||null

					this.ruleForm.RefundReason = this.ruleForm.RefundReason.map(v=>{
						return {
							value:v,
							key:Date.now()
						}
					})
				} catch (e) {
					//TODO handle the exception
				} finally {
					this.$nextTick(function() {
						if (this.$refs.myTextEditor.quill) {
							this.$refs.myTextEditor.quill.enable(true);
							this.$refs.myTextEditor.quill.blur();
							window.scrollTo(0, 0);
						}

					});
				}
			},
			async saveMallInfo() {
				try {
					let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
					if (!ruleForm.AddressArr.length || ruleForm.AddressArr == null) {
						ruleForm.ReceiveArea = ''
						ruleForm.ReceiveAreaId = null
						ruleForm.ReceiveCity = ''
						ruleForm.ReceiveCityId = null
						ruleForm.ReceiveProvince = ''
						ruleForm.ReceiveProvinceId = null
					}
					ruleForm.MemberFirstFromValidityDay = ruleForm.MemberFirstFromValidityType === 1 ? ruleForm.ValidityDay1 : ruleForm.ValidityDay2

					if (!this.mallInfo.IsUseQyWeixin) { //是否使用企业微信为否  
						//隐藏联系客服 并选择优先联系客服
						// ruleForm.ContactPriority = 0
					}
					if (!this.mallInfo.IsMoreShop) {
						//隐藏商详页店铺信息  并默认选择显示商城
						ruleForm.WapProductDetailShopInfoDisplayType = 1
						//多门店关闭时 默认选中线上商城
						ruleForm.IsOpenPickUpOrderOnlinePay = true
					}
					// if(this.mallInfo.ComponentId == 2){
					// 	ruleForm.IsOpenPickUpOrderOnlinePay = true;
					// 	ruleForm.IsOpenPickUpOrderOfflinePay = false;
					// }
					ruleForm.OrderRemarkConfig = ruleForm.OrderRemarkConfig.map((v,i)=>{
						if(!v.Name){
							v.Name = '留言'+(i+1)
						}
						return v
					})
					ruleForm.RefundReason = ruleForm.RefundReason.map(v=>v.value).filter(v=>v)

					if(!ruleForm.IsOpenPickUpOrderAutoRefund){
						ruleForm.IsOpenPickUpOrdinaryOrderAutoRefund = false
						ruleForm.IsOpenPickUpActivityOrderAutoRefund = false
					}

					if(!ruleForm.IsOpenPickUpOrdinaryOrderAutoRefund){
						ruleForm.PickUpOrdinaryOrderAutoRefundPaidMoney = 1
						ruleForm.PickUpOrdinaryOrderAutoRefundInStoreDay = 7
					}
					if(!ruleForm.IsOpenPickUpActivityOrderAutoRefund){
						ruleForm.PickUpActivityOrderAutoRefundPaidMoney = 1
						ruleForm.PickUpActivityOrderAutoRefundInStoreDay = 7
					}
					if(!ruleForm.IsOpenPickUpOrderTakeGoodsNotice){
						ruleForm.PickUpOrderTakeGoodsNoticeNoEnterStoreDay = 1
					}


					let data = {
						MallInfo: ruleForm
					};
					// console.log(data.MallInfo.MainCategory,data.MallInfo.SpecialProdctCategorys)
					let res = await savemallbaseinfo(data);
					if (res.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: res.Message
						});

						this.getData()
						this.$store.dispatch('GetPcMallName', {}).then(() => {}).catch((e) => {})
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			// 查看示例
			lookShow(type) {
				this.imgType = type
				if (type == 1) {
					this.lookPicture =
						'https://cdn.dkycn.cn/melyshop/image/20200309-c3c2dea4-78ad-4a6b-9679-90ed834545c4.png'
				} else if (type == 2) {
					this.lookPicture =
						'https://cdn.dkycn.cn/melyshop/image/20200309-baadf905-6483-4e9e-9ecc-2dd62188be92.png'
				} else if (type == 3) {
					this.lookPicture =
						'https://cdn.dkycn.cn/melyshop/image/20200319-5be96ed9-e606-4983-b9b9-f34a0a556cda.png'
				}else if (type == 4) {
					this.lookPicture =
						'https://cdn.dkycn.cn/melyshop/image/mallinfo-5.1.2.png'
				}
				this.lookVisable = true
			},
			//获取地址数据
			getDataList() {
				var that = this;
				axios
					.get('https://cdn.dkycn.cn/JSON/gdDistrict-v2.json?v='+new Date().getTime(), {})
					.then(function(response) {
						that.dataList = response.data;
					})
					.catch(function(error) {});
			},
			handleAddressChange() {
				this.ruleForm.ReceiveProvinceId = this.ruleForm.AddressArr[0];
				this.ruleForm.ReceiveCityId = this.ruleForm.AddressArr[1];
				this.ruleForm.ReceiveAreaId = this.ruleForm.AddressArr[2];
				this.dataList.map(item => {
					if (item.id == this.ruleForm.ReceiveProvinceId) {
						this.ruleForm.ReceiveProvince = item.name;
						item.child.map(items => {
							if (items.id == this.ruleForm.ReceiveCityId) {
								this.ruleForm.ReceiveCity = items.name;
								items.child.map(lastitems => {
									if (lastitems.id == this.ruleForm.ReceiveAreaId) {
										this.ruleForm.ReceiveArea = lastitems.name;
									}
								});
							}
						});
					}
				});
			},
			handleRemove(file, fileList) {},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			// 保存
			saveSupiler(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.saveMallInfo()
					} else {
						this.$message.error('请完善商城设置')
						this.$nextTick(()=>{
							let iserror = document.getElementsByClassName('is-error')
							iserror[0].scrollIntoView({
								block:'center',
								behavior:'smooth'
							})
						})
						return false;
					}
				});
			},
			handleChange(value) {},
			handleAvatarSuccess(res, file) {
				// const isLt50k = file.size / 1024 < 50;
				// if (!isLt50k) {
				// 	alert('上传图片大小不能超过50kB哦!');
				// 	return false;
				// }
				this.ruleForm.MallLogoUrl = res[0];
				this.$forceUpdate();
			},
			handleAvatarSuccessBook(res, file) {
				this.ruleForm.WxRefundCertImgUrl = res[0]
			},
			handlePosterHeadSuccess(res, file) {
				const isLt200k = file.size / 1024 < 200;
				if (!isLt200k) {
					this.$message({
						type: 'error',
						message: '分享海报头图大小请控制在200kb及以内',
						showClose: true
					})
					return false;
				}
				this.ruleForm.ProductShareHeadPic = res[0];
				// this.$forceUpdate();
			},
			shareIconSuccess(res, file) {
				this.ruleForm.ShareIconImgUrl = res[0]
			},
			beforeAvatarUploadshare(file) {
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!isLt1M) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '分享图标请控制在1M以内!'
					})
				}
				return isLt1M
			},
			handleFooterAvatarSuccess(res) {
				this.ruleForm.MallButtomLogoUrl = res[0];
				this.$forceUpdate();
			},
			deleteMallButtomLogoUrl() {
				this.ruleForm.MallButtomLogoUrl = ''
			},
			// 选择在商品轮播公共图/商品详情公共图
			handleCheckShufflingPublic(e) {
				this.ruleForm.IsOpenProductShufflingPublicImg = e
				if (!this.ruleForm.IsOpenProductShufflingPublicImg) {
					this.ruleForm.ProductShufflingPublicImgUrl = ''
					this.ruleForm.PublicImgLocation = 0
				}
			},
			// 选择在商品轮播公共图/商品详情公共图
			handleCheckDetailPublic(e) {
				this.ruleForm.IsOpenProductDetailPublicImg = e
				if (!this.ruleForm.IsOpenProductDetailPublicImg) {
					this.ruleForm.ProductDetailPublicImgUrl = ''
				}
			},
			handleGoodsCarouselSuccess(res, file) {
				const isLt2M = file.size / 1024 < 2048;
				if (!isLt2M) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '图片大小请控制在2M以内!'
					})
					return false;
				}
				this.ruleForm.ProductShufflingPublicImgUrl = res[0];
				this.$forceUpdate();
			},
			handleGoodsDetaileSuccess(res, file) {
				const isLt2M = file.size / 1024 < 2048;
				if (!isLt2M) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '图片大小请控制在2M以内!'
					})
					return false;
				}
				this.ruleForm.ProductDetailPublicImgUrl = res[0];
				this.$forceUpdate();
			}
		}
	};
</script>

<style lang="less" scoped>
.nobold{
	::v-deep .el-form-item__label{
		font-weight: 400;
	}
}
.AreaBox{
	.top{
		background: #F8F8F9;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		border: 1px solid #EBEEF5;
		padding: 15px 10px;
		font-size: 14px;
		color: #606266;
		line-height: 1.5;
		font-weight: bold;
	}
	.contentBox{
		background: #FFFFFF;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		border: 1px solid #EBEEF5;
		border-top: none;
		padding: 15px 10px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		font-size: 14px;
		color: #606266;
		line-height: 1.5;
	}
}
.noteSelect{
	::v-deep .el-input {
		width: 120px !important;
	}
}
.titFont{
	font-size: 14px;
	color: #303133;
	font-weight: bold;
}
.grayFont{
	font-size: 14px;
	color: #909399;
	line-height: 1.5;
}
.flexRow{
	display: flex;
	align-items: center;
}
.grayfont{
	font-size: 14px;color:#999;line-height:1.5;
	margin-left:110px
}
</style>
<style lang="less" scoped>
	.formErr-left10{
		::v-deep .el-form-item__error{
			left:10px
		}
	}
	.jump-container {
		border: 1px solid #E6A23C;
		background-color: #FDF6EC;
		color: #E6A23C;
		font-size: 14px;
		line-height: 20px;
		padding: 10px;
		margin-top: -20px;
		margin-left: 120px;
	}

	.container {
		// padding: 10px;
		width: 100%;
		overflow: hidden;

		::v-deep .el-input {
			width: 300px;
		}

		::v-deep .el-textarea {
			width: 500px;
		}

		.box-card {
			::v-deep .el-card__header {
				padding: 10px 20px;
			}

			.clearfix {
				line-height: 36px;
			}
		}

		.poster-head-form {
			.avatar-uploader {
				::v-deep .el-upload--picture-card {
					width: 332px;
					height: 102px;
					line-height: 100px;
					border-radius: 0;
				}

				::v-deep .upload-avatar {
					width: 100px;
					height: 100px;

				}
			}

			.default-avatar {
				width: 332px;
				height: 102px;
				border: 1px dashed #c0ccda;

				.avatar {
					width: 100%;
					height: 70px;
					position: relative;
					overflow: hidden;
				}

				.avatar::after {
					content: attr(posterHeadBrand);
					color: #fff;
					font-size: 20px;
					text-align: center;
					line-height: 70px;
					width: 120%;
					position: absolute;
					height: 70px;
					border-radius: 0 0 50% 50%;
					background-color: var(--posterHeadColor);
					left: -10%;
				}
			}
		}

		.flex-box {
			display: flex;
			flex-direction: row;
			margin-top: 10px;
		}
	}

	.headIconImg {
		position: relative;

		.iconImgmasking {
			position: absolute;
			top: 0;
			left: 0;
			background: rgba(0, 0, 0, 0.5);
			width: 145px;
			height: 145px;
			z-index: 2;
		}

		.iconimgBox {
			position: absolute;
			top: 0;
			left: 0;
			width: 145px;
			height: 145px;
			text-align: center;
			line-height: 145px;
			z-index: 3;
			color: #fff;
		}

	}

	.payMoneys-with {
		.filter-container .filter-item {
			margin-bottom: 0;
			margin-right: 0;
		}
	}


	.upload-avatar {
		width: 145px;
		height: 145px;
		object-fit: contain;
	}

	.footer {
		background-color: #fff;
		width: 100%;
		margin-right: 30px;
		height: 80px;
		text-align: center;
		line-height: 80px;
		position: fixed;
		bottom: 0px;
		z-index: 10;
	}

	::v-deep .el-color-picker--medium .el-color-picker__trigger {
		width: 145px !important;
	}

	.other-card {
		::v-deep .el-form-item__label {
			width: 260px !important;
		}

		::v-deep .el-form-item__content {
			// margin-left: 260px !important;
		}
	}
</style>
